import {createContext, useState} from 'react';

const DistributionPointSelected = createContext();

export const MyDistributionPoint = ({ children }) => {
    const [distributionPoint, setDistributionPoint] = useState('');

    return (
        <DistributionPointSelected.Provider value={{distributionPoint, setDistributionPoint}}>
            {children}
        </DistributionPointSelected.Provider>
    )
}

export default DistributionPointSelected;