import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    getPagePermissions,
    CONFIG_APPLICATION
} from '../../security/permissions';
import { ConfigurationApp } from './components/ConfigurationApp';

class ConfigurationApplication extends Component {


    render() {
        return (
            <ConfigurationApp isCompanyRegistered={true}/>
        );
    }

}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(CONFIG_APPLICATION, permissions)
});

export default connect(mapStateToProps)(ConfigurationApplication);
