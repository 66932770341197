import { useEffect, useState } from 'react';

import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Row,
    Spin,
    Switch,
    Typography,
    Upload,
    notification, Modal, Space, Anchor, message, Skeleton
} from 'antd'
import {dispensaries as dispensaryServices} from "../../../services";
import HumanCronExpressionInput from "./humanCronExpressionInput";
import {EyeInvisibleOutlined, EyeTwoTone, UploadOutlined} from "@ant-design/icons";
import {RfcModal} from './RFCModal';
import {returnToRegister} from '../../../security';

const { Text } = Typography;
const { Link } = Anchor;

export const ConfigurationApp = ({ isCompanyRegistered }) => {

    const [form] = Form.useForm()

    const [configApp, setConfigApp] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [visibleModalProveedores, setVisibleModalProveedores] = useState(false);


    const onChangeAllowManualWarehouseLevel = (value) => {
        form.setFieldsValue({allowManualWarehouseLevel: value })
    }

    const getConfigApp = () => {
        setLoading(true);
        dispensaryServices.get('configApplication/getConfig')
            .then(response => {
                setConfigApp(response);
                form.setFieldsValue({allowManualWarehouseLevel: response.allowManualWarehouseLevel })
                setLoading(false);
            });
    }

    const onFinish = (values) => {
        setLoading(true)
        let configApplicationURL = "configApplication".concat(isCompanyRegistered ? "/changeConfigApplication": "");
        const authenticationTokenExpirationConfig = values.authenticationTokenExpirationConfig * 1000 * 60;
        const valuesUpdated = {...values, authenticationTokenExpirationConfig}
        dispensaryServices.post(configApplicationURL, valuesUpdated)
            .then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error(jsonResponse.response.data);
                } else {
                    message.success("Configuración actualizada correctamente");
                    setConfigApp(response);
                    if(!isCompanyRegistered){
                        returnToRegister();
                    }
                    setLoading(false)
                }
            });
    };

    useEffect(() => {
        setLoading(true)
        getConfigApp();
    }, []);

    const hideModal = (e) => {
       setVisibleModalProveedores(!visibleModalProveedores);
    }
    const rfcUpdated = (value) => {
        let rfcProveedores = configApp.rfcProveedores = value;
        setConfigApp({...configApp, rfcProveedores});
        form.setFieldsValue({rfcProveedores: rfcProveedores })
    }


    const [certificate, setCertificate] = useState(null);
    const [privateKey, setPrivateKey] = useState(null);
    const [passwordKey, setPasswordKey] = useState('')
    const [uploading, setUploading] = useState(false);

    const uploadCertificateProps = {
        onRemove: (file) => {
            setCertificate(null);
        },
        beforeUpload: (file) => {
            setCertificate(file);
            return false;
        },
        certificate,
    }
    const uploadPrivateKeyProps = {
        onRemove: (file) => {
            setPrivateKey(null);
        },
        beforeUpload: (file) => {
            setPrivateKey(file);
            return false;
        },
        privateKey,
    }
    const uploadFiles = () => {
        const formData = new FormData();
        formData.append('certificate', certificate);
        formData.append('privateKey', privateKey);
        formData.append('passwordKey', passwordKey)
        setUploading(true);

        dispensaryServices.post('configApplication/upload/fiel', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            Modal.success({
                title: 'Firma electrónica validada',
                content:(
                    <div>
                        <Space direction="vertical">
                            <Text>Nombre o razón social: { response.name }</Text>
                            <Text>FIEL válida hasta: { response.expireAt }</Text>
                        </Space>
                    </div>
                ),
                footer: (_, { OkBtn }) => (
                    <OkBtn/>
                ),
            });
        }).catch(error => {
            notification.error({
                message: "Error en archivos del SAT",
                description: error.response.data.message,
            })
        })
        setUploading(false)
    }

    const onChangePassword = (ev) => {
        const value = ev.target.value;
        setPasswordKey(value);
    }

    const cronPopOverContent = (
        <Anchor><Link title="Generador de crons" target="https://www.freeformatter.com/cron-expression-generator-quartz.html" /></Anchor>
    );

    if(!configApp) return (
        <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </>
    );
    return (
        <Spin size={"large"} tip={"Cargando..."} spinning={loading}>
            <Form
                layout='vertical'
                onFinish={onFinish}
                form={form}
            >
                <Row>
                    <Form.Item extra="* Campos obligatorios">
                        <Typography.Text className={"instructions"}>Llena los campos a continuación para configuración del sistema.</Typography.Text>
                    </Form.Item>
                </Row>
                <Divider orientation="left" style={{fontSize: '15px', color: '#606676'}}>Datos de configuración</Divider>
                <Row gutter={24}>
                    <Col span={12} className={"label-inline"}>
                        <Form.Item
                            name={'authenticationTokenExpirationConfig'}
                            label="Vencimiento de token de autenticación (en minutos)"
                            initialValue={configApp.authenticationTokenExpirationConfig ? configApp.authenticationTokenExpirationConfig/60/1000 : ""}
                            rules={[{ required: true, message: 'Por favor introduce el tiempo (en minutos) de Vencimiento de token de autenticación.' }]}
                        >
                            <InputNumber style={{width:'100%'}} min={10} step={1} precision={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'calculateDailyVolumeValuesCron'}
                            label="Cron calculo de los valores de volumen diario"
                            initialValue={configApp.calculateDailyVolumeValuesCron || "0 00 05 */1 * *"}
                        >
                            <HumanCronExpressionInput showAdvanceMode availablePeriods={[{key: "Diariamente", value: "*/1"}]} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="RFC's de Proveedores"
                            help="Ingresa los RFC de los proveedores que deseas que aparezcan en el reporte JSON"
                            name={"rfcProveedores"}
                            initialValue={configApp.rfcProveedores}
                        >
                            <Input className="common-input" onClick={hideModal}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'allowManualWarehouseLevel'}
                            label="Permitir ingreso de medición de nivel manual por tanque"
                        >
                            <Switch onChange={onChangeAllowManualWarehouseLevel} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Configuración de Importación de CFDI</Divider>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'cfdiImportConnectorCron'}
                            label="Cron de consumo de CFDI del Conector"
                            initialValue={configApp.cfdiImportConnectorCron || "0 00 05 */1 * *"}
                            rules={[
                                {required: true, message: 'Por favor introduce un cron de consulta de cfdi.'}
                            ]}
                        >
                            <HumanCronExpressionInput showAdvanceMode availablePeriods={[{key: "Diariamente", value: "*/1"}]}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"invoiceAppUrl"}
                            label="URL del servidor de facturación"
                            help="Ingresa la URL del servidor de facturación"
                            initialValue={configApp.invoiceAppUrl}
                            rules={[{ required: true, message: 'Por favor ingresa la URL'}]}
                        >
                            <Input className="common-input"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" style={{fontSize: '15px', color: '#606676'}}>Firma electrónica</Divider>
                <Row gutter={24}>
                    <Col span={6}>
                        <Upload accept={'.cer,.cert,.crs'} name={'certificate'} maxCount={1} {...uploadCertificateProps}>
                            <Button icon={<UploadOutlined />}>Certificado(.cer)</Button>
                        </Upload>
                    </Col>
                    <Col span={6}>
                        <Upload accept={'.key'} name={'privateKey'} maxCount={1} {...uploadPrivateKeyProps}>
                            <Button icon={<UploadOutlined />}>Clave privada(.key)</Button>
                        </Upload>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'downloadSatCFDICron'}
                            label="Cron de descarga de CFDIs del SAT"
                            initialValue={configApp.downloadSatCFDICron || "0 */2 * * *"}
                        >
                            <HumanCronExpressionInput
                                availablePeriods={[
                                    { key: "Diariamente", value: "*/1" },
                                    { key: "Cada 2 horas", value: "0 */2" },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item label={'Contraseña Firma Electrónica'}>
                            <Input.Password className="common-input" size={'small'} onChange={onChangePassword} iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Button
                            type={'primary'}
                            style={{ marginLeft: 16, marginTop: 30, width: 170 }}
                            onClick={uploadFiles}
                            disabled={certificate === null || privateKey === null || passwordKey === ''}
                            loading={uploading}
                        >
                            {uploading ? 'Validando' : 'Validar archivos'}
                        </Button>
                    </Col>
                </Row>
                <Divider/>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        {loading ? 'Guardando Configuraciones' : 'Guardar Configuraciones'}
                    </Button>
                </Form.Item>
            </Form>

            <RfcModal
                visible={visibleModalProveedores}
                onAccept={hideModal}
                rfcPrev={configApp.rfcProveedores}
                rfcUpdated={rfcUpdated}
            />
        </Spin>
    );
};
