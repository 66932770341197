import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    DatePicker,
    Form,
    Select,
    Row,
    Col,
    Divider,
    Table,
    Statistic,
    Pagination, Flex, Popover, Modal, message, Space
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import { dispensaries as dispensaryServices } from '../../services';
import request from '../../services/request';
import { serializeParams } from '../../common/helpers';
import { AssignCfdi } from './AssignCFDI';
import DistributionPointSelected from '../../context/MyDistributionPoint';
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined} from '@ant-design/icons';
import {ComplementsComponent} from '../index';

const { RangePicker } = DatePicker;

export default function Index() {
    const [form] = Form.useForm();
    const { distributionPoint } = useContext(DistributionPointSelected);
    const [distributionPointId, setDistributionPointId] = useState(getSelectedDistributionPoint());

    const [ distributionPointInfo, setDistributionPointInfo ] = useState({});
    const [ currentComplement, setCurrentComplement ] = useState({});
    const [filters, setFilters] = useState({
        equipmentKeys: [],
        startDate: dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        displayPage: 0,
        displayAmount: 10,
        distributionPointId,
    });
    const [ showComplement, setShowComplement ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [movementDetail, setMovementDetail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [dispensariesData, setDispensariesData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ showModalDelete, setShowModalDelete ] = useState(false);
    const [ selectedAll, setSelectedAll ] = useState(false);
    const [ allKeys, setAllKeys ] = useState([]);
    const hasSelected = selectedRowKeys.length > 0;

    const [showSpan, setShowSpan] = useState(false);

    useEffect(() => {
        if (distributionPoint) {
            setDistributionPointId(distributionPoint);
            fetchDistributionPointInfo();
            fetchDispensaries(distributionPoint);
            resetFormAndData();
        }
    }, [distributionPoint]);

    useEffect(() => {
        if (selectedAll) {
            setShowSpan(true);
            const timer = setTimeout(() => {
                setShowSpan(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [selectedAll]);

    const fetchDispensaries = (dpp) => {
        if (dpp) {
            dispensaryServices.get(`distributionPoints/${dpp}/dispensaries`)
                .then(response => setDispensariesData(response));
        }
    };

    const fetchDistributionPointInfo = () => {
        dispensaryServices.get(`distributionPoints/${distributionPoint}`)
            .then(response => setDistributionPointInfo(response));
    }

    const fetchEquipmentMovements = (newFilters) => {
        setIsLoading(true);
        const queryParams = {
            page: newFilters.displayPage,
            size: newFilters.displayAmount,
            sort: "folio,DESC",
        };
        request().post(`movements/getMovementsByEquipment${serializeParams(queryParams)}`, newFilters)
            .then((response) => {
                const movementDataWithKeys = response.data.movementDetail.content.map(movement => ({
                    ...movement,
                    key: movement.idMovement,
                }));
                setData(response.data);
                setMovementDetail(movementDataWithKeys);
                setPagination({ ...pagination, total: response.data.movementDetail.totalElements });
                setCurrentPage(newFilters.displayPage + 1);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const resetFormAndData = () => {
        form.setFieldsValue({ hose: [] });
        setMovementDetail([]);
        setData({});
        setAllKeys([]);
        setSelectedRowKeys([]);
        setPagination({});
        setSelectedAll(false)
        setShowSpan(false)
    };

    const onFinish = (values) => {
        const { hose, period } = values;
        const updatedFilters = {
            ...filters,
            equipmentKeys: hose,
            startDate: period[0].format('YYYY-MM-DD HH:mm:ss'),
            endDate: period[1].format('YYYY-MM-DD HH:mm:ss'),
            distributionPointId,
        };
        setAllKeys([]);
        setFilters(updatedFilters);
        setSelectedRowKeys([]);
        fetchEquipmentMovements(updatedFilters);
    };

    const optionHoses = dispensariesData.map(dispensary => ({
        label: <span>{dispensary.dispensaryCode}</span>,
        title: dispensary.dispensaryCode,
        options: dispensary.hoses?.map(hose => ({
            label: hose.responseHoseCode,
            value: hose.responseHoseCode,
        })) || [],
    }));

    const changeView = (params) => {
        let complement = {
            currentCfdiComplement: params.currentCfdiComplement,

            isCfdiComplement: params.currentCfdiComplement === null ? false : true,

            currentTransportComplement: params.currentTransportComplement,
            isTransportComplement: params.currentTransportComplement === null ? false : true,

            currentStorageComplement: params.currentStorageComplement,
            isStorageComplement: params.currentStorageComplement === null ? false : true,

            currentInternationalComplement: params.currentInternationalComplement,
            isInternationalComplement: params.currentInternationalComplement === null ? false : true,

            isCurrentEditAllowed: params.isCurrentEditable,

            currentCFDIRegisterType:"VENTA",
            currentCFDIType:"INGRESO",

            inputRegisterUUID: params.inputRegisterUUID,
            clarifications: params.clarifications,
        };

        setCurrentComplement(complement)
        setShowComplement(params.showComplement);
    }

    const handleDelete = (params) => {
        setShowModalDelete(true);
        const toDelete = {
            //General info
            receptionUUID: params.inputRegisterUUID,
            transactionType: 'venta',

            cfdiComplementRegisterType: params.cfdiComplementRegisterType,

            //CFDI Complement Data
            isCfdiComplement: false,
            currentcfdiComplementUUID: params.currentCfdiComplement !== null ? params.currentCfdiComplement.id : null,

            //Transport Complement Data
            isTransportComplement: false,
            transportComplementUUID: params.currentTransportComplement !== null ? params.currentTransportComplement.id : null,

            //Storage Complement Data
            isStorageComplement: false,
            storageComplementUUID: params.currentStorageComplement !== null ? params.currentStorageComplement.id : null,

            //International Complement Data
            isInternationalComplement: false,
            internationalComplementUUID: params.currentInternationalComplement !== null ? params.currentInternationalComplement.id : null,

            delete: (params.delete !== null) ? params.delete : false,

        };
        setCurrentComplement(toDelete);
    }

    const handleOk = () => {
        setIsLoading(true)

        dispensaryServices.put('complements/editComplement', currentComplement)
            .then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error(jsonResponse.response.data);
                } else {
                    message.success('Complemento eliminado');
                    setIsLoading(false);
                    setShowModalDelete(false);
                }
            });
    }

    const closeComplementView = () => {
        setShowComplement(false);
        fetchEquipmentMovements(filters);
    }

    const columns = [
        { title: 'Fecha y hora', dataIndex: 'movementDate', render: date => moment(date).format('YYYY/MM/DD HH:mm:ss') },
        { title: 'Folio', dataIndex: 'movementFolio' },
        { title: 'Tipo', dataIndex: 'movementType' },
        { title: 'Dispensario', dataIndex: 'responseDispensaryCode' },
        { title: 'Manguera', dataIndex: 'responseEquipmentKey' },
        { title: 'Tipo de producto', dataIndex: 'responseProductDesc' },
        { title: 'Litros vendidos', dataIndex: 'volume' },
        { title: 'Precio por litro', dataIndex: 'price', render: text => `$${text}` },
        { title: 'Importe total', dataIndex: 'total', render: text => `$${text}` },
        { title: 'UUID', dataIndex: 'fiscalId', render: text => text ? text.toUpperCase() : '' },
        {
            title: 'Complemento',
            dataIndex: 'complement',
            render: (_, record) => {
                const isEditable = record.isEditAllowed;
                const hasComplements = record.cfdiComplement || record.storageComplement || record.transportComplement || record.internationalComplement || record.clarifications;

                if (isEditable && !hasComplements) {
                    return (
                        <Popover content="Agregar información para complemento">
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => changeView({
                                    mainView: false,
                                    showComplement: true,
                                    currentCfdiComplement: record.cfdiComplement,
                                    currentTransportComplement: record.transportComplement,
                                    currentStorageComplement: record.storageComplement,
                                    currentInternationalComplement: record.internationalComplement,
                                    isCurrentEditable: record.isEditAllowed,
                                    inputRegisterUUID: record.idMovement,
                                    clarifications: record.clarifications,
                                })}
                            />
                        </Popover>
                    );
                }

                if (isEditable && hasComplements) {
                    return (
                        <Space>
                            <Popover content="Editar información de complemento">
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => changeView({
                                        mainView:false,
                                        showComplement:true,
                                        currentCfdiComplement: record.cfdiComplement,
                                        currentTransportComplement: record.transportComplement,
                                        currentStorageComplement: record.storageComplement,
                                        currentInternationalComplement: record.internationalComplement,
                                        isCurrentEditable: record.isEditAllowed,
                                        inputRegisterUUID: record.idMovement,
                                        clarifications: record.clarifications,
                                    })}
                                />
                            </Popover>
                            <Popover content="Eliminar información de complemento">
                                <Button
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => handleDelete({
                                        inputRegisterUUID: record.idMovement,
                                        currentCfdiComplement: record.cfdiComplement,
                                        currentTransportComplement: record.transportComplement,
                                        currentStorageComplement: record.storageComplement,
                                        currentInternationalComplement: record.internationalComplement,
                                        cfdiComplementRegisterType: record.movementType,
                                        clarifications: record.clarifications,
                                        delete: true,
                                    })}
                                />
                            </Popover>
                        </Space>
                    );
                }

                if (!isEditable && hasComplements) {
                    return (
                        <Popover content="Ver información de complemento">
                            <Button
                                icon={<EyeOutlined />}
                                onClick={() => changeView({
                                    mainView:false,
                                    showComplement:true,
                                    currentCfdiComplement: record.cfdiComplement,
                                    currentTransportComplement: record.transportComplement,
                                    currentStorageComplement: record.storageComplement,
                                    currentInternationalComplement: record.internationalComplement,
                                    isCurrentEditable: record.isEditAllowed,
                                    inputRegisterUUID: record.idMovement,
                                    clarifications: record.clarifications,
                                })}
                            />
                        </Popover>
                    );
                }

                return <span>Sin complemento asociado</span>;
            }
        }
    ];


    const onSelectChange = (selectedRowKeys, selectedRows, info) => {
        const { type } = info;

        if(type === 'all') {
            setSelectedAll(prev => !prev);
        }

        const selectedKeys = {
            page: `page_${currentPage}`,
            keys: selectedRowKeys,
        };

        setAllKeys(prevState => {
            const pageExists = prevState.find(item => item.page === selectedKeys.page);
            if (pageExists) {
                return prevState.map(item =>
                    item.page === selectedKeys.page ? selectedKeys : item
                );
            } else {
                return [...prevState, selectedKeys];
            }
        });
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onPageChange = (page, pageSize) => {
        const updatedFilters = {
            ...filters,
            displayPage: page - 1,
        };
        setFilters(updatedFilters);
        fetchEquipmentMovements(updatedFilters);
        const keys = allKeys.map(item => item.keys);
        setSelectedRowKeys(keys.flat());
        setSelectedAll(false);
        setShowSpan(false);
    };

    const onShowSizeChange = (current, pageSize) => {
        const updatedFilters = {
            ...filters,
            displayPage: 0,
            displayAmount: pageSize,
        };
        setFilters(updatedFilters);
        fetchEquipmentMovements(updatedFilters);
    };

    const closeModal = () => {
        setShowModal(false)
        fetchEquipmentMovements(filters);
    }
    const onAssigned = () => {
        setShowModal(false)
        setAllKeys([]);
        setSelectedRowKeys([]);
        fetchEquipmentMovements(filters);
    }
    const closeModalDelete = () => {
        setShowModalDelete(false);
        fetchEquipmentMovements(filters);
    }

    const openModal = () => setShowModal(true);

    return (
        <div>
            { !showComplement &&
                <div>
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Row gutter={16} align="bottom">
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Form.Item
                                    name="hose"
                                    label="Seleccionar mangueras"
                                    initialValue={filters.equipmentKeys.length > 0 ? filters.equipmentKeys : undefined}
                                    rules={[{required: true, message: 'Por favor selecciona al menos una manguera.'}]}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Selecciona una o más mangueras"
                                        style={{width: '100%'}}
                                        options={optionHoses}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Form.Item
                                    name="period"
                                    label="Seleccionar periodo"
                                    initialValue={[dayjs().subtract(7, 'days').startOf('day'), dayjs().endOf('day')]}
                                    rules={[{required: true, message: 'Por favor selecciona un periodo.'}]}
                                >
                                    <RangePicker
                                        showTime
                                        format="YYYY/MM/DD HH:mm:ss"
                                        style={{width: '100%'}}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={4} lg={4} style={{display: 'flex', alignItems: 'flex-end'}}>
                                <Form.Item style={{width: '100%'}}>
                                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                                        Consultar
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={4} style={{display: 'flex', alignItems: 'flex-end'}}>
                                <Form.Item style={{width: '100%'}}>
                                    <Button style={{width: '100%'}}>
                                        Descargar CSV
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Divider style={{marginTop: '10px'}}/>
                    <div style={{background: '#E5E5E5', padding: '30px'}}>
                        <Row>
                            <Col span={6}>
                                <h3 style={{color: '#1B95CA'}}>TOTAL</h3>
                            </Col>
                            <Col span={6}>
                                <Statistic title="Litros vendidos" value={data.totalVolume ?? 0}/>
                            </Col>
                            <Col span={6}>
                                <Statistic title="Total venta" prefix="$" value={data.totalPrice ?? 0}/>
                            </Col>
                        </Row>
                    </div>
                    <div style={{margin: 16}}>
                        <span style={{color: '#1B95CA'}}>{`Mangueras`}</span>
                    </div>
                    <Flex align="center" gap="middle">
                        <Button type="primary" onClick={openModal} disabled={!hasSelected}>
                            Asignar CFDI
                        </Button>
                        {hasSelected ? `Seleccionada${allKeys.map(item => item.keys).flat().length === 1 ? '' : 's'} ${allKeys.map(item => item.keys).flat().length} ${allKeys.map(item => item.keys).flat().length === 1 ? 'venta' : 'ventas'}` : ''}

                        {showSpan && (
                            <span style={{ marginLeft: '10px', color: 'green' }}>
                                ¡Se han seleccionados todos en la pagina {currentPage}!
                            </span>
                        )}
                    </Flex>
                    <Divider />
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={movementDetail}
                        pagination={false}
                        loading={isLoading}
                    />
                    <br/>
                    <Pagination
                        current={currentPage}
                        total={pagination.total}
                        onChange={onPageChange}
                        onShowSizeChange={onShowSizeChange}
                        pageSizeOptions={['10', '20', '50']}
                        showSizeChanger
                        showTotal={total => `Total ${total} elementos`}
                    />
                    {
                        showModal &&
                        <AssignCfdi open={showModal}
                                    onCancel={closeModal}
                                    selectedKeys={allKeys.map(item => item.keys).flat()}
                                    onAssigned={onAssigned}

                        />
                    }
                    {
                        showModalDelete &&
                        <Modal
                            open={showModalDelete}
                            title="Atención"
                            onCancel={closeModalDelete}
                            footer={[
                                <Button className="back-button" key="cancel-delete" onClick={closeModalDelete}>Cancelar</Button>,
                                <Button type="danger" key="delete" loading={isLoading} onClick={handleOk}>Confirmar</Button>
                            ]}
                        >
                            <p>¿Está seguro que desea eliminar este complemento? Una vez realizada la acción, la información no podrá ser recuperada.</p>
                        </Modal>
                    }
                    <Divider />
                </div>
            }
            {
                showComplement &&
                <ComplementsComponent
                    transactionType='venta'
                    complementType={distributionPointInfo.companyActivityTypeDescription}
                    currentComplementInfo={currentComplement}
                    handleReturnToTable={closeComplementView}
                />
            }
        </div>
    );
}
