import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from "dayjs";
import {
    Row,
    Col,
    Input,
    Button,
    message,
    DatePicker,
    Modal,
    Divider,
    Checkbox,
    Select,
    InputNumber,
    Tooltip,
    Typography,
} from 'antd';
import {
    register as registerServices
} from '../../services';
import tooltip from './tooltip.json';
import {filterCombo, patternRFC, validateCantidadMonetaria, validateValorNumerico} from './../../services/utils/rfcValidator';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const { Option } = Select;
const { TextArea } = Input;

class ComplementsComponent extends Component {
    state = {
        loading: false,
        visible: false,
        data: {},
        redirectToRecepcion: false,
        transactionType:"",
        currentComplementInfo: {},
        RFCValidator: {},
        uuidValidator: {},
        importExportPermission: {},
        importExportPlace: {},
        isNewRegister: true,
        countries: [],
        incoterms: [],
        customsDeclaration: [],
        patternRFC: patternRFC,
        patternPermiso: "^(H/[0-9]{1,5}/COM/[0-9]{4})|(PL/[0-9]{1,5}/EXP/ES/[0-9]{4})|(PL/[0-9]{1,5}/EXP/ES/MM/[0-9]{4})|(PL/[0-9]{1,5}/EXP/ESA/[0-9]{4})|(H/[0-9]{1,5}/COM/[0-9]{4})|(PL/[0-9]{1,5}/EXP/AE/[0-9]{4})|(G/[0-9]{1,5}/COM/GN/[0-9]{4})|(G/[0-9]{1,5}/COM/PETRO/[0-9]{4})|(G/[0-9]{1,5}/COM/CEE/[0-9]{4})|(G/[0-9]{1,5}/EXP/ES/FE/[0-9]{4})|(G/[0-9]{1,5}/EXP/ES/MM/[0-9]{4})|(G/[0-9]{1,5}/LICUE/[0-9]{4})|(G/[0-9]{1,5}/REG/[0-9]{4})|(LP/[0-9]{1,5}/EXP/ES/[0-9]{4})|(LP/[0-9]{1,5}/EXP/AUT/[0-9]{4})|(LP/[0-9]{1,5}/COM/[0-9]{4})|(PL/[0-9]{1,5}/EXP/ESA/MM/[0-9]{4})|(SENER-REF-[0-9]{1,3}-[0-9]{4})|(SENER-TP-[0-9]{1,3}-[0-9]{4})|(SENER-CPG-[0-9]{1,3}-[0-9]{4})$",
        patternpermisoCRE: "^(PL/[0-9]{1,5}/TRA/OM/[0-9]{4})|(PL/[0-9]{1,5}/TRA/DUC/[0-9]{4})|(PL/[0-9]{1,5}/TRA/TM/[0-9]{4})|(PQ/[0-9]{1,5}/TRA/DUC/[0-9]{4})|(G/[0-9]{1,5}/TUP/[0-9]{4})|(G/[0-9]{1,5}/SAB/[0-9]{4})|(G/[0-9]{1,5}/TRA/OM/[0-9]{4})|(P/[0-9]{1,5}/TRA/TM/[0-9]{4})|(P/[0-9]{1,5}/TRA/OM/[0-9]{4})|(G/[0-9]{1,5}/TRA/[0-9]{4})|(GN/[0-9]{1,5}/P/TRA/DUC/[0-9]{4})|(GN/[0-9]{1,5}/TRA/DUC/[0-9]{4})|(P/[0-9]{1,5}/TRA/DUC/[0-9]{4})|(P/[0-9]{1,5}/P/TRA/DUC/[0-9]{4})|(LP/[0-9]{1,5}/TRA/DUC/[0-9]{4})|(G/[0-9]{1,5}/LPT/[0-9]{4})|(LP/[0-9]{1,5}/TRA/[0-9]{4})$",
        patternPermisoAlmacenamiento: "^(PL/[0-9]{1,5}/ALM/[0-9]{4})|(PQ/[0-9]{1,5}/ALM/[0-9]{4})|(PL/[0-9]{1,5}/ALM/AE/[0-9]{4})|(G/[0-9]{1,5}/ALM/[0-9]{4})|(P/[0-9]{1,5}/ALM/[0-9]{4})|(LP/[0-9]{1,5}/ALM/[0-9]{4})|(G/[0-9]{1,5}/LPA/[0-9]{4})$",

    };

    componentDidMount() {
        this.setState({
            transactionType: this.props.transactionType,
            currentComplementInfo: this.props.currentComplementInfo,
            complementType: this.props.complementType,
        });

        registerServices.get(`complement/getCountries`)
            .then(response => {
                this.setState({ countries: response });
            });

        registerServices.get(`complement/getIncoterms`)
            .then(response => {
                this.setState({ incoterms: response });
            });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }

    handleAddSubmit = () => {
        this.setState({ isNewRegister: true })
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.showModal();
            }
        });
    }

    handleEditSubmit = () => {
        this.setState({ isNewRegister: false })
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.showModal();
            }
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOk = () => {
        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const filters = {
                    //General info
                    receptionUUID: this.state.currentComplementInfo.inputRegisterUUID,
                    transactionType: this.state.transactionType,
                    
                    //CFDI Complement Data
                    isCfdiComplement: this.state.currentComplementInfo.isCfdiComplement,
                    currentcfdiComplementUUID: values.currentcfdiComplementUUID,
                    cfdiComplementUUID: values.cfdiComplementUUID,
                    cfdiComplementRfc: values.cfdiComplementRfc,
                    cfdiComplementName: values.cfdiComplementName,
                    cfdiComplementPermission: values.cfdiComplementPermission,
                    cfdiComplementRegisterType: values.cfdiComplementRegisterType,
                    cfdiComplementType: values.cfdiComplementType,
                    cfdiComplementPrice: values.cfdiComplementPrice,
                    cfdiComplementVolume: values.cfdiComplementVolume,
                    cfdiComplementInvoiceDate: values.cfdiComplementInvoiceDate != null ? values.cfdiComplementInvoiceDate.format('YYYY-MM-DD HH:mm:ss') : null,                    
                    
                    //Transport Complement Data
                    isTransportComplement: this.state.currentComplementInfo.isTransportComplement,
                    transportComplementUUID: values.transportComplementUUID,
                    transportComplementPermission: values.transportComplementPermission,
                    transportComplementVehicleKey: values.transportComplementVehicleKey,
                    transportComplementTariffAmount: values.transportComplementTariffAmount,
                    transportComplementDuctCapacityAmount: values.transportComplementDuctCapacityAmount,
                    transportComplementDuctUseAmount: values.transportComplementDuctUseAmount,
                    transportComplementDuctVolumetricAmount: values.transportComplementDuctVolumetricAmount,
                    transportComplementSupplyAmount: values.transportComplementSupplyAmount,
                    
                    //Storage Complement Data
                    isStorageComplement: this.state.currentComplementInfo.isStorageComplement,
                    storageComplementUUID: values.storageComplementUUID,
                    storageComplementTerminal: values.storageComplementTerminal,
                    storageComplementPermission: values.storageComplementPermission,
                    storageComplementTariffAmount: values.storageComplementTariffAmount,
                    storageComplementCapacityAmount: values.storageComplementCapacityAmount,
                    storageComplementUseAmount: values.storageComplementUseAmount,
                    storageComplementVolumetricAmount: values.storageComplementVolumetricAmount,

                    //International Complement Data
                    isInternationalComplement: this.state.currentComplementInfo.isInternationalComplement,
                    internationalComplementUUID: values.internationalComplementUUID,
                    internationalComplementPermission: values.internationalComplementPermission,
                    internationalComplementImportExportPlace: values.internationalComplementImportExportPlace,
                    internationalComplementCountryCode: values.internationalComplementCountryCode,
                    internationalComplementImportExportMethod: values.internationalComplementImportExportMethod,
                    internationalComplementCustomsDeclaration: values.internationalComplementCustomsDeclaration,
                    internationalComplementIncoterms: values.internationalComplementIncoterms,
                    internationalComplementImportExportAmount: values.internationalComplementImportExportAmount,
                    internationalComplementVolume: values.internationalComplementVolume,

                    hasClarifications: this.state.currentComplementInfo.hasClarifications,
                    clarifications: values.clarifications,

                    delete: false,
                };
                if (this.state.isNewRegister === true) {
                    registerServices.post('complements/insertComplement', filters)
                        .then(response => {
                            const jsonResponse = JSON.parse(JSON.stringify(response));
                            if (jsonResponse.hasOwnProperty('response')) {
                                message.error(jsonResponse.response.data);
                            } else {
                                this.setState({ loading: false, visible: false });
                                message.success(response);
                                this.props.handleReturnToTable();
                            }
                        });
                } else {
                    registerServices.put('complements/editComplement', filters)
                        .then(response => {
                            const jsonResponse = JSON.parse(JSON.stringify(response));
                            if (jsonResponse.hasOwnProperty('response')) {
                                message.error(jsonResponse.response.data);
                            } else {
                                this.setState({ loading: false, visible: false });
                                message.success(response);
                                this.props.handleReturnToTable();
                            }
                        });
                }
            }
        });
    }

    onChangeCfdiCheckbox = e => {
        this.setState(prevState => ({
            currentComplementInfo: {
                ...prevState.currentComplementInfo,
                isCfdiComplement: e.target.checked,
            }
        }));

        if (e.target.checked === false) {
            this.props.form.setFieldsValue({ cfdiComplementUUID: null });
            this.props.form.setFieldsValue({ cfdiComplementRfc: null });
            this.props.form.setFieldsValue({ cfdiComplementName: null });
            this.props.form.setFieldsValue({ cfdiComplementPermission: null });
            this.props.form.setFieldsValue({ cfdiComplementPrice: null });
            this.props.form.setFieldsValue({ cfdiComplementVolume: null });
            this.props.form.setFieldsValue({ cfdiComplementInvoiceDate: null });
        }
    };

    onChangeTransportCheckbox = e => {
        this.setState(prevState => ({
            currentComplementInfo: {
                ...prevState.currentComplementInfo,
                isTransportComplement: e.target.checked,
            }
        }));

        if (e.target.checked === false) {
            this.props.form.setFieldsValue({ transportComplementPermission: null });
            this.props.form.setFieldsValue({ transportComplementVehicleKey: null });
            this.props.form.setFieldsValue({ transportComplementTariffAmount: null });
            this.props.form.setFieldsValue({ transportComplementDuctCapacityAmount: null });
            this.props.form.setFieldsValue({ transportComplementDuctUseAmount: null });
            this.props.form.setFieldsValue({ transportComplementDuctVolumetricAmount: null });
            this.props.form.setFieldsValue({ transportComplementSupplyAmount: null });
        }
    };

    onChangeStorageCheckbox = e => {
        this.setState(prevState => ({
            currentComplementInfo: {
                ...prevState.currentComplementInfo,
                isStorageComplement: e.target.checked,
            }
        }));

        if (e.target.checked === false) {
            this.props.form.setFieldsValue({ storageComplementTerminal: null });
            this.props.form.setFieldsValue({ storageComplementPermission: null });
            this.props.form.setFieldsValue({ storageComplementTariffAmount: null });
            this.props.form.setFieldsValue({ storageComplementCapacityAmount: null });
            this.props.form.setFieldsValue({ storageComplementUseAmount: null });
            this.props.form.setFieldsValue({ storageComplementVolumetricAmount: null });
        }
    };

    onChangeInternationalCheckbox = e => {
        this.setState(prevState => ({
            currentComplementInfo: {
                ...prevState.currentComplementInfo,
                isInternationalComplement: e.target.checked,
            }
        }));

        if (e.target.checked === false) {
            this.props.form.setFieldsValue({ internationalComplementPermission: null });
            this.props.form.setFieldsValue({ internationalComplementImportExportPlace: null });
            this.props.form.setFieldsValue({ internationalComplementCountryCode: null });
            this.props.form.setFieldsValue({ internationalComplementImportExportMethod: null });
            this.props.form.setFieldsValue({ internationalComplementCustomsDeclaration: null });
            this.props.form.setFieldsValue({ internationalComplementIncoterms: null });
            this.props.form.setFieldsValue({ internationalComplementImportExportAmount: null });
            this.props.form.setFieldsValue({ internationalComplementVolume: null });
        }
    };

    onChangeClarifications = e => {
        this.setState(prevState => ({
            currentComplementInfo: {
                ...prevState.currentComplementInfo,
                hasClarifications: e.target.value.length > 0 ? e.target.value : null,
            }
        }));
    };

    onBlurRfc = (e) => {
        const { value } = e.target;
        const rfcFisicaPattern = /^([A-ZÑ]|\&){4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/;
        const rfcMoralPattern = /^([A-ZÑ]|\&){3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/;

        if (rfcFisicaPattern.test(value) || rfcMoralPattern.test(value)) {
            this.setState({ RFCValidator: { ...this.validateRfcFormat(true), } });
        }else{
            this.setState({ RFCValidator: { ...this.validateRfcFormat(false), } });
        }
    }

    validateRfcFormat(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `Formato de RFC no válido`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    onBlurUUID = (e) => {
        const { value } = e.target;
        const uuidPattern = /^[a-f0-9A-F]{8}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{12}$/;

        if (uuidPattern.test(value)) {
            this.setState({ uuidValidator: { ...this.validateUUIDFormat(true), } });
        }else{
            this.setState({ uuidValidator: { ...this.validateUUIDFormat(false), } });
        }
    }

    validateUUIDFormat(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `Formato de UUID no válido, el UUID debe tener formato: ^[a-f0-9A-F]{8}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{12}$`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    onBlurImportExportPermission = (e) => {
        const { value } = e.target;
        const internationalImportExportPermissionPattern = /^[a-zA-Z0-9]{4}C[a-zA-Z0-9]{9}$/;

        if (internationalImportExportPermissionPattern.test(value)) {
            this.setState({ importExportPermission: { ...this.validateImportExportPermissionFormat(true), } });
        }else{
            this.setState({ importExportPermission: { ...this.validateImportExportPermissionFormat(false), } });
        }
    }

    validateImportExportPermissionFormat(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `Formato de permiso de importación o exportación inválido, el permiso debe seguir el patrón: ^[a-zA-Z0-9]{4}C[a-zA-Z0-9]{9}$`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    onBlurImportExportPlace = (e) => {
        const {value} = e.target;
        const importExportPlacePattern = /^(0[1-2]|0[5-8]|1[1-2]|14|1[6-9]|20|2[2-8]|3[0-1]|3[3-4]|3[7-9]|40|4[2-4]|4[6-8]|5[0-3]|6[4-5]|67|73|75|8[0-4])([0-7])?$/;
        
        if (importExportPlacePattern.test(value)) {
            this.setState({ importExportPlace: { ...this.validateImportExportPlaceFormat(true), } });
        }else{
            this.setState({ importExportPlace: { ...this.validateImportExportPlaceFormat(false), } });
        }
    }

    validateImportExportPlaceFormat(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `Clave de aduana inválido, la clave debe seguir el patrón: ^(0[1-2]|0[5-8]|1[1-2]|14|1[6-9]|20|2[2-8]|3[0-1]|3[3-4]|3[7-9]|40|4[2-4]|4[6-8]|5[0-3]|6[4-5]|67|73|75|8[0-4])([0-7])?$`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    onBlurCustomsDeclaration = (e) => {
        const {value} = e.target;
        const customsDeclarationPattern = /^[0-9]{2} (0[1-2]|0[5-8]|1[1-2]|14|1[6-9]|20|2[2-8]|3[0-1]|3[3-4]|3[7-9]|40|4[2-4]|4[6-8]|5[0-3]|6[4-5]|67|73|75|8[0-4]) [0-9]{4} [0-9](?!0{6})[0-9]{6}$/;
        
        if (customsDeclarationPattern.test(value)) {
            this.setState({ customsDeclaration: { ...this.validateCustomsDeclarationFormat(true), } });
        }else{
            this.setState({ customsDeclaration: { ...this.validateCustomsDeclarationFormat(false), } });
        }
    }

    validateCustomsDeclarationFormat(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `Número de pedimento aduanal inválido, la clave debe seguir el patrón: ^[0-9]{2} (0[1-2]|0[5-8]|1[1-2]|14|1[6-9]|20|2[2-8]|3[0-1]|3[3-4]|3[7-9]|40|4[2-4]|4[6-8]|5[0-3]|6[4-5]|67|73|75|8[0-4]) [0-9]{4} [0-9](?!0{6})[0-9]{6}$`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const { visible, loading } = this.state;

        const optionCountries = types => types.map(item => (
            <Option key={item.id} value={item.saaiM3Code}>{"".concat(item.saaiM3Code).concat(" - ".concat(item.country)) }</Option>
        ));

        const optionIncoterms = types => types.map(item => (
            <Option key={item.id} value={item.code}>{"".concat(item.code).concat(" - ".concat(item.description))}</Option>
        ));

        return (
            <Form layout='vertical' >
                <div>
                    <Row>
                        <Typography.Title level={3}>Complemento {this.state.complementType}</Typography.Title>
                    </Row>
                    <Row>
                        <label>Llena los campos a continuación para asociar al complemento.</label>
                    </Row>
                    <Form.Item />
                </div>
                {/* FORM PARA COMPLEMENTO DE CFDI   */}
                <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>CFDI</Divider>
                <Row gutter={24}>
                    <Col>
                        <Form.Item >
                            {getFieldDecorator('isCfdiComplement', {
                            })(
                                <div>
                                    <Checkbox
                                        checked={this.state.currentComplementInfo.isCfdiComplement}
                                        onChange={this.onChangeCfdiCheckbox}
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                    <span style={{ color: '#24a0d6', fontSize: '16px' }}>Selecciona la casilla si cuentan con la factura de {this.state.transactionType} </span>
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    {getFieldDecorator('currentcfdiComplementUUID', {
                        initialValue: this.props.currentComplementInfo.currentCfdiComplement !== null ? this.props.currentComplementInfo.currentCfdiComplement.id : null,
                    })(
                        <Input hidden disabled/>
                    )}
                </Form.Item>
                
                <Row gutter={24} hidden={this.state.currentComplementInfo.isCfdiComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={(this.state.transactionType==='compra')? tooltip.cfdi.compras.rfc: tooltip.cfdi.ventas.rfc}>
                            <Form.Item 
                                label="Rfc del cliente o proveedor"
                                validateStatus={this.state.RFCValidator.validateStatus}
                                help={this.state.RFCValidator.errorMsg}
                            >
                                {getFieldDecorator('cfdiComplementRfc', {
                                    initialValue: this.props.currentComplementInfo.currentCfdiComplement !== null ? this.props.currentComplementInfo.currentCfdiComplement.rfc : null,
                                    rules: [{
                                        required: this.state.currentComplementInfo.isCfdiComplement, message: 'Por favor introduce el RFC del cliente o proovedor.'
                                    }, {
                                        pattern: this.state.patternRFC, message: 'El RFC no parece tener un formato valido.'
                                    }],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Introduce el RFC del contribuyente"}
                                        onBlur={this.onBlurRfc}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={(this.state.transactionType==='compra')? tooltip.cfdi.compras.nombre: tooltip.cfdi.ventas.nombre}>
                            <Form.Item label="Nombre del cliente o proveedor">
                                    {getFieldDecorator('cfdiComplementName', {
                                        initialValue: this.props.currentComplementInfo.currentCfdiComplement !== null ? this.props.currentComplementInfo.currentCfdiComplement.name : null,
                                        rules: [
                                            {
                                                required: this.state.currentComplementInfo.isCfdiComplement, message: 'Por favor introduce el nombre del cliente o proovedor.'
                                            }, {
                                                min: 10, message: 'Introduce mínimo 10 caracteres.'
                                            }, {
                                                max: 150, message: 'No se puede exceder los 150 caracteres.'
                                            }
                                        ],
                                    })(
                                        <Input 
                                            disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                            placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Nombre del cliente o proovedor en factura"}
                                        />
                                    )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={(this.state.transactionType==='compra')? tooltip.cfdi.compras.uuid: tooltip.cfdi.ventas.uuid}>
                            <Form.Item 
                                label="UUID de factura"
                                validateStatus={this.state.uuidValidator.validateStatus}
                                help={this.state.uuidValidator.errorMsg}
                            >
                                {getFieldDecorator('cfdiComplementUUID', {
                                    initialValue: this.props.currentComplementInfo.currentCfdiComplement !== null ? this.props.currentComplementInfo.currentCfdiComplement.id : null,
                                    rules: [{
                                        required: this.state.currentComplementInfo.isCfdiComplement, message: 'Por favor introduce el UUID de la factura.'
                                    }],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                        onBlur={this.onBlurUUID}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "UUID documentado en factura"}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <Row gutter={24} hidden={this.state.currentComplementInfo.isCfdiComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={(this.state.transactionType==='compra')? tooltip.cfdi.compras.permiso: tooltip.cfdi.ventas.permiso}>
                            <Form.Item label="Permiso del cliente o proveedor">
                                {getFieldDecorator('cfdiComplementPermission', {
                                    initialValue: this.props.currentComplementInfo.currentCfdiComplement !== null ? this.props.currentComplementInfo.currentCfdiComplement.permission : null,
                                    rules: [{
                                        required: false, message: 'Por favor introduce el permiso del cliente o proovedor.',
                                       }, {
                                        pattern: this.state.patternPermiso, message: "El valor ingresado no tiene un formato valido"
                                       }],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Introduce el permiso del cliente o proovedor"}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={(this.state.transactionType==='compra')? tooltip.cfdi.compras.tipoFactura: tooltip.cfdi.ventas.tipoFactura}>
                            <Form.Item label="Tipo de factura">
                                {getFieldDecorator('cfdiComplementType', {
                                    initialValue: this.state.currentComplementInfo.currentCFDIType,
                                })(
                                    <Select>
                                        <Option key={'INGRESO'} value={'INGRESO'}>{'Ingreso'}</Option>
                                        <Option key={'EGRESO'} value={'EGRESO'}>{'Egreso'}</Option>
                                        <Option key={'TRASLADO'} value={'TRASLADO'}>{'Traslado'}</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={(this.state.transactionType==='compra')? tooltip.cfdi.compras.tipoTransaccion: tooltip.cfdi.ventas.tipoTransaccion}>
                            <Form.Item label="Tipo de transacción">
                                {getFieldDecorator('cfdiComplementRegisterType', {
                                    initialValue: this.state.currentComplementInfo.currentCFDIRegisterType,
                                })(
                                    <Select disabled>
                                        <Option key={'RECEPCION'} value={'RECEPCION'}>{'Recepción'}</Option>
                                        <Option key={'VENTA'} value={'VENTA'}>{'Venta'}</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <Row gutter={24} hidden={this.state.currentComplementInfo.isCfdiComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.cfdi.fechaHora}>
                            <Form.Item label="Fecha y hora de la factura">
                                {getFieldDecorator('cfdiComplementInvoiceDate', {
                                    initialValue: this.props.currentComplementInfo.currentCfdiComplement !== null ? dayjs(this.props.currentComplementInfo.currentCfdiComplement.invoiceDate) : null,
                                    rules: [{
                                        required: this.state.currentComplementInfo.isCfdiComplement, message: 'Por favor introduce la fecha de la factura.'
                                    }],
                                })(
                                    <DatePicker
                                        lang='es'
                                        showTime={true}
                                        showToday={false}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Fecha y hora de factura"}
                                        style={{ width: '100%' }} 
                                        format={'DD-MM-YYYY HH:mm:ss'} 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.cfdi.precioNeto}>
                            <Form.Item label="Precio neto documentado en factura">
                                {getFieldDecorator('cfdiComplementPrice', {
                                    initialValue: this.props.currentComplementInfo.currentCfdiComplement !== null ? this.props.currentComplementInfo.currentCfdiComplement.price : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isCfdiComplement, message: 'Por favor introduce el precio.'
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Precio documentado en factura"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.cfdi.volumen}>
                            <Form.Item label="Volumen documentado">
                                {getFieldDecorator('cfdiComplementVolume', {
                                    initialValue: this.props.currentComplementInfo.currentCfdiComplement !== null ? this.props.currentComplementInfo.currentCfdiComplement.volume : null,
                                    rules: [{
                                        required: this.state.currentComplementInfo.isCfdiComplement, message: 'Por favor introduce el volumen.'
                                    }, {
                                        validator: validateValorNumerico
                                    }],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Volumen documentado en factura"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                {/* FORM PARA COMPLEMENTO DE TRANSPORTE */}
                <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Transporte</Divider>
                <Row gutter={24}>
                    <Col>
                        <Form.Item >
                            {getFieldDecorator('isTransportComplement', {
                            })(
                                <div>
                                    <Checkbox
                                        checked={this.state.currentComplementInfo.isTransportComplement}
                                        onChange={this.onChangeTransportCheckbox}
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                    <span style={{ color: '#24a0d6', fontSize: '16px' }}>Selecciona la casilla si cuentan con servicios de transporte en la {this.state.transactionType} </span>
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    {getFieldDecorator('transportComplementUUID', {
                        initialValue: this.props.currentComplementInfo.currentTransportComplement !== null ? this.props.currentComplementInfo.currentTransportComplement.id : null,
                    })(
                        <Input hidden disabled/>
                    )}
                </Form.Item>
                
                <Row gutter={24} hidden={this.state.currentComplementInfo.isTransportComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.transporte.permisoCRE}>
                            <Form.Item label="Permiso otorgado por la CRE a quien realiza el servicio">
                                {getFieldDecorator('transportComplementPermission', {
                                    initialValue: this.props.currentComplementInfo.currentTransportComplement !== null ? this.props.currentComplementInfo.currentTransportComplement.permission : null,
                                    rules: [{
                                        required: this.state.currentComplementInfo.isTransportComplement, message: 'Por favor introduce el número de permiso.'
                                    }, {
                                        pattern: this.state.patternpermisoCRE, message: "El valor no tiene un formato valido"
                                    }],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed} 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Introduce el número de permiso"}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.transporte.claveVehiculo}>
                            <Form.Item label="Clave del vehículo que transporta">
                                {getFieldDecorator('transportComplementVehicleKey', {
                                    initialValue: this.props.currentComplementInfo.currentTransportComplement !== null ? this.props.currentComplementInfo.currentTransportComplement.vehicleKey : null,
                                    rules: [
                                        {
                                            required: false
                                        }, {
                                            min: 6, message: 'Introduce mínimo 6 caracteres.'
                                        }, {
                                            max: 12, message: 'No se puede exceder los 12 caracteres.'
                                        }
                                    ],
                                })(
                                    <Input
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Obligatorio si el transporte no es por ducto"} 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.transporte.tarifaTransporte}>
                            <Form.Item label="Tarifa de transporte">
                                {getFieldDecorator('transportComplementTariffAmount', {
                                    initialValue: this.props.currentComplementInfo.currentTransportComplement !== null ? this.props.currentComplementInfo.currentTransportComplement.tariffAmount : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isTransportComplement, message: 'Por favor introduce la tarifa.'
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Introduce la tarifa de transporte"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <Row gutter={24} hidden={this.state.currentComplementInfo.isTransportComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.transporte.cargoCapacidad}>
                            <Form.Item label="Cargo por capacidad de transporte">
                                {getFieldDecorator('transportComplementDuctCapacityAmount', {
                                    initialValue: this.props.currentComplementInfo.currentTransportComplement !== null ? this.props.currentComplementInfo.currentTransportComplement.ductCapacityAmount : null,
                                    rules: [
                                        {
                                            required: false
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Obligatorio sólo si existe en servicio de transporte por ducto"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.transporte.cargoUso}>
                            <Form.Item label="Cargo por uso">
                                {getFieldDecorator('transportComplementDuctUseAmount', {
                                    initialValue: this.props.currentComplementInfo.currentTransportComplement !== null ? this.props.currentComplementInfo.currentTransportComplement.ductUseAmount : null,
                                    rules: [
                                        {
                                            required: false
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Obligatorio sólo si existe en servicio de transporte por ducto"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                    </Tooltip>
                        
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.transporte.cargoVolumetrico}>
                            <Form.Item label="Cargo volumétrico por servicio de transporte">
                                {getFieldDecorator('transportComplementDuctVolumetricAmount', {
                                    initialValue: this.props.currentComplementInfo.currentTransportComplement !== null ? this.props.currentComplementInfo.currentTransportComplement.ductVolumetricAmount : null,
                                    rules: [
                                        {
                                            required: false
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Obligatorio sólo si existe en servicio de transporte por ducto"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <Row gutter={24} hidden={this.state.currentComplementInfo.isTransportComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.transporte.tarifaSuministro}>
                            <Form.Item label="Tarifa de suministro">
                                {getFieldDecorator('transportComplementSupplyAmount', {
                                    initialValue: this.props.currentComplementInfo.currentTransportComplement !== null ? this.props.currentComplementInfo.currentTransportComplement.supplyAmount : null,
                                    rules: [
                                        {
                                            required: false
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Obligatorio sólo si existe en servicio de transporte por ducto"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>                

                {/* FORM PARA COMPLEMENTO DE ALMACENAMIENTO */}
                <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Almacén</Divider>
                <Row gutter={24}>
                    <Col>
                        <Form.Item >
                            {getFieldDecorator('isStorageComplement', {
                            })(
                                <div>
                                    <Checkbox
                                        checked={this.state.currentComplementInfo.isStorageComplement}
                                        onChange={this.onChangeStorageCheckbox}
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                    <span style={{ color: '#24a0d6', fontSize: '16px' }}>Selecciona la casilla si cuentan con servicios de almacén en la {this.state.transactionType} </span>
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    {getFieldDecorator('storageComplementUUID', {
                        initialValue: this.props.currentComplementInfo.currentStorageComplement !== null ? this.props.currentComplementInfo.currentStorageComplement.id : null,
                    })(
                        <Input hidden disabled/>
                    )}
                </Form.Item>
                
                <Row gutter={24} hidden={this.state.currentComplementInfo.isStorageComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.almacenamiento.terminal}>
                            <Form.Item label="Terminal de almacenamiento del producto">
                                {getFieldDecorator('storageComplementTerminal', {
                                    initialValue: this.props.currentComplementInfo.currentStorageComplement !== null ? this.props.currentComplementInfo.currentStorageComplement.terminal : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isStorageComplement, message: 'Por favor introduce nombre de la terminal de almacenamiento.'
                                        },
                                        {
                                            min: 5, message: "Por favor ingrese al menos 5 caracteres."
                                        },
                                        {
                                            max: 250, message: "No se pueden ingresar más de 250 caracteres."
                                        },
                                    ],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed} 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Introduce el nombre de la terminal de almacenamiento"}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.almacenamiento.permiso}>
                            <Form.Item label="Permiso de almacenamiento">
                                {getFieldDecorator('storageComplementPermission', {
                                    initialValue: this.props.currentComplementInfo.currentStorageComplement !== null ? this.props.currentComplementInfo.currentStorageComplement.permission : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isStorageComplement, message: 'Por favor introduce el permiso de almacenamiento.'
                                        }, {
                                            pattern: this.state.patternPermisoAlmacenamiento, message: "El valor ingresado no tiene un formato valido"
                                        }
                                    ],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Número de permiso otorgado por la CRE a la terminal de almacenamiento."}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.almacenamiento.tarifa}>
                            <Form.Item label="Tarifa de almacenamiento">
                                {getFieldDecorator('storageComplementTariffAmount', {
                                    initialValue: this.props.currentComplementInfo.currentStorageComplement !== null ? this.props.currentComplementInfo.currentStorageComplement.tariffAmount : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isStorageComplement, message: 'Por favor introduce la tarifa de almacenamiento.'
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Ingresa la tarifa de almacenamiento"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <Row gutter={24} hidden={this.state.currentComplementInfo.isStorageComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.almacenamiento.cargoCapacidad}>
                            <Form.Item label="Cargo por capacidad de almacenamiento">
                                {getFieldDecorator('storageComplementCapacityAmount', {
                                    initialValue: this.props.currentComplementInfo.currentStorageComplement !== null ? this.props.currentComplementInfo.currentStorageComplement.capacityAmount : null,
                                    rules: [
                                        {
                                            required: false
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Ingresa el cargo por capacidad de almacenamiento"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.almacenamiento.cargoUso}>
                            <Form.Item label="Cargo por uso de almacenamiento">
                                {getFieldDecorator('storageComplementUseAmount', {
                                    initialValue: this.props.currentComplementInfo.currentStorageComplement !== null ? this.props.currentComplementInfo.currentStorageComplement.useAmount : null,
                                    rules: [
                                        {
                                            required: false
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Ingresa el cargo por uso de almacenamiento"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.almacenamiento.cargoVolumetrico}>
                            <Form.Item label="Cargo volumétrico de almacenamiento">
                                {getFieldDecorator('storageComplementVolumetricAmount', {
                                    initialValue: this.props.currentComplementInfo.currentStorageComplement !== null ? this.props.currentComplementInfo.currentStorageComplement.volumetricAmount : null,
                                    rules: [
                                        {
                                            required: false
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Ingresa el cargo volumétrico de almacenamiento"}
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                {/* FORM PARA COMPLEMENTO INTERNACIONAL   */}
                <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Transacción internacional</Divider>
                <Row gutter={24}>
                    <Col>
                        <Form.Item >
                            {getFieldDecorator('isInternationalComplement', {
                            })(
                                <div>
                                    <Checkbox
                                        checked={this.state.currentComplementInfo.isInternationalComplement}
                                        onChange={this.onChangeInternationalCheckbox}
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                    />
                                    <span style={{ color: '#24a0d6', fontSize: '16px' }}>Selecciona la casilla si la {this.state.transactionType} fue internacional</span>
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    {getFieldDecorator('internationalComplementUUID', {
                        initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.id : null,
                    })(
                        <Input hidden disabled/>
                    )}
                </Form.Item>
                
                <Row gutter={24} hidden={this.state.currentComplementInfo.isInternationalComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.internacional.permiso}>
                            <Form.Item 
                                label="Permiso de importación o exportación"
                                validateStatus={this.state.importExportPermission.validateStatus}
                                help={this.state.importExportPermission.errorMsg}
                            >
                                {getFieldDecorator('internationalComplementPermission', {
                                    initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.permission : null,
                                    rules: [{
                                        required: this.state.currentComplementInfo.isInternationalComplement, message: 'Por favor introduce el permiso de importación o exportación.'
                                    }],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed} 
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Introduce el permiso de importación o exportación"}
                                        onBlur={this.onBlurImportExportPermission}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.internacional.punto}>
                            <Form.Item 
                                label="Punto de internación o extracción"
                                validateStatus={this.state.importExportPlace.validateStatus}
                                help={this.state.importExportPlace.errorMsg}
                            >
                                {getFieldDecorator('internationalComplementImportExportPlace', {
                                    initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.importExportPlace : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isInternationalComplement, message: 'Por favor introduce dos dígitos para la clave de aduana y de existir, uno para la sección aduanera.'
                                        }, {
                                            min: 2, message: 'Introduce mínimo 2 caracteres.'
                                        }, {
                                            max: 3, message: 'No se puede exceder los 3 caracteres.'
                                        }
                                    ],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Por favor introduce dos dígitos para la clave de aduana y de existir, uno para la sección aduanera"}
                                        onBlur={this.onBlurImportExportPlace}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.internacional.pais}>
                            <Form.Item label="País de origen o destino">
                                {getFieldDecorator('internationalComplementCountryCode', {
                                    initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.countryCode : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isInternationalComplement, message: 'Por favor selecciona el país de origen o destino.'
                                        },
                                    ],
                                })(
                                    <Select showSearch filterOption={filterCombo}>
                                        {optionCountries(this.state.countries)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <Row gutter={24} hidden={this.state.currentComplementInfo.isInternationalComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.internacional.medio}>
                            <Form.Item label="Medio de entrada o salida de la aduana">
                                {getFieldDecorator('internationalComplementImportExportMethod', {
                                    initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.importExportMethod : null,
                                    rules: [{
                                        required: this.state.currentComplementInfo.isInternationalComplement, message: 'Por favor selecciona el medio de entrada o salida de aduana.'
                                    }],
                                })(
                                    <Select showSearch filterOption={filterCombo}>
                                        <Option key={'1'} value={'1'}>{'Marítimo'}</Option>
                                        <Option key={'2'} value={'2'}>{'Ferroviario de doble estiba'}</Option>
                                        <Option key={'3'} value={'3'}>{'Carretero-Ferroviario'}</Option>
                                        <Option key={'6'} value={'6'}>{'Ferroviario'}</Option>
                                        <Option key={'7'} value={'7'}>{'Carretero'}</Option>
                                        <Option key={'8'} value={'8'}>{'Tubería'}</Option>
                                        <Option key={'11'} value={'11'}>{'Ductos'}</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.internacional.pedimento}>
                            <Form.Item 
                                label="Pedimento aduanal"
                                validateStatus={this.state.customsDeclaration.validateStatus}
                                help={this.state.customsDeclaration.errorMsg}
                            >
                                {getFieldDecorator('internationalComplementCustomsDeclaration', {
                                    initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.customsDeclaration : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isInternationalComplement, message: 'Por favor introduce el número de pedimento aduanal.'
                                        }
                                    ],
                                })(
                                    <Input 
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Por favor introduce el número de pedimento aduanal"}
                                        onBlur={this.onBlurCustomsDeclaration}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={tooltip.internacional.precio}>
                            <Form.Item label="Precio de importación o exportación">
                                {getFieldDecorator('internationalComplementImportExportAmount', {
                                    initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.importExportAmount : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isInternationalComplement, message: 'Por favor introduce el precio de importación o exportación.'
                                        },{
                                            validator: validateCantidadMonetaria
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Ingresa el precio de importación o exportación"}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>

                <Row gutter={24} hidden={this.state.currentComplementInfo.isInternationalComplement !== true}>
                    <Col span={8}>
                        <Tooltip title={tooltip.internacional.volumen}>
                            <Form.Item label="Volumen de importación o exportación">
                                {getFieldDecorator('internationalComplementVolume', {
                                    initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.volume : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isInternationalComplement, message: 'Por favor introduce el precio de importación o exportación.'
                                        },{
                                            validator: validateValorNumerico
                                        }
                                    ],
                                })(
                                    <InputNumber 
                                        style={{ width: '100%' }} 
                                        precision={3}    
                                        disabled={!this.state.currentComplementInfo.isCurrentEditAllowed}
                                        placeholder={!this.state.currentComplementInfo.isCurrentEditAllowed ? "" : "Ingresa el volumen de importación o exportación"}
                                    />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={16}>
                        <Tooltip title={tooltip.internacional.incoterms}>
                            <Form.Item label="Incoterms">
                                {getFieldDecorator('internationalComplementIncoterms', {
                                    initialValue: this.props.currentComplementInfo.currentInternationalComplement !== null ? this.props.currentComplementInfo.currentInternationalComplement.incoterms : null,
                                    rules: [
                                        {
                                            required: this.state.currentComplementInfo.isInternationalComplement, message: 'Por favor selecciona los incoterms.'
                                        },
                                    ],
                                })(
                                    <Select showSearch filterOption={filterCombo}>
                                        {optionIncoterms(this.state.incoterms)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>


                {/* TextArea Aclaraciones por venta, recepcion, traspaso  */}
                <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Aclaraciones</Divider>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="Aclaraciones">
                            {getFieldDecorator('clarifications', {
                                initialValue: this.state.currentComplementInfo.clarifications !== null ? this.state.currentComplementInfo.clarifications : null,
                                rules: [
                                    {
                                        required: false
                                    }, {
                                        min: 10, message: 'Introduce mínimo 10 caracteres.'
                                    }
                                ],
                            })(
                                <TextArea placeholder={'Ingrese texto'} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>


                {/* BOTONES DE CONFIRMACIÓN Y CANCELACIÓN DE PROCESO */}
                <Row gutter={24}>
                    <Col span={3}>
                        <Form.Item>
                            {(this.props.currentComplementInfo.currentCfdiComplement === null && this.props.currentComplementInfo.currentTransportComplement === null && this.props.currentComplementInfo.currentStorageComplement === null && this.props.currentComplementInfo.currentInternationalComplement === null && this.props.currentComplementInfo.clarifications === null) ?
                                    <Button
                                        hidden={(this.state.currentComplementInfo.isCurrentEditAllowed === false) ||
                                        (this.state.currentComplementInfo.clarifications !== null)}
                                        name="addButton" className="customized-primary" type="primary" onClick={() => this.handleAddSubmit()} disabled={hasErrors(getFieldsError())}>Agregar</Button> :
                                    <Button
                                        hidden={((this.state.currentComplementInfo.clarifications === ''))}
                                        name="editButton" className="customized-primary" type="primary" onClick={() => this.handleEditSubmit()} disabled={hasErrors(getFieldsError())}>Editar</Button>}
                        </Form.Item>
                    </Col>

                    <Col span={2}>
                        <Form.Item>
                            <Button className="customized-primary" type="primary" onClick={() => this.props.handleReturnToTable()}>Regresar</Button>

                            <Modal
                                open={visible}
                                title="Atención"
                                onCancel={this.handleCancel}
                                footer={[
                                    <Button className="back-button" key="back" onClick={this.handleCancel}>Revisar información</Button>,
                                    <Button className="submit-button" key="submit" loading={loading} onClick={this.handleOk}>Confirmar registro</Button>
                                ]}
                            >
                                <p>Por favor asegúrese de que la información en el formulario sea la correcta.</p>
                            </Modal>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default Form.create({ name: 'ComplementsComponent' })(ComplementsComponent);
