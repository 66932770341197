import {Col, Input, Row, Select, Switch, TimePicker, Tooltip, Space } from "antd";
import moment from "moment";
import React from "react";

class HumanCronExpressionInput extends React.Component {
    constructor(props) {
        super(props);
        const value = props.value || {};
        
        this.state = {
            value,
            isAdvancedMode:false,
            showSelectMode: props.showAdvanceMode,
        };

        this.availablePeriods = (props.availablePeriods)
            ? props.availablePeriods 
            : [
                {key: "Diariamente", value: "*/1"},
                {key: "Cada 3 días", value: "*/3"},
            ];
    }

    resolveActualHour(value){
        if(value===undefined){
            return "05:00"
        }
        return value.slice(5, 7).concat(":").concat(value.slice(2, 4));
    }

    resolveActualPeriod(value){
        if(value===undefined){
            return "*/1";
        }
        return value.slice(7, 11).trim();
    }    

    getDefaultPeriods = () => {
        return this.availablePeriods.map(period => {
            return (<Select.Option value={period.value} key={period.key}>{period.key}</Select.Option>)
        });
    }

    submitChanges = (newHour, newPeriod) => {
        let selectedHour = (newHour!==undefined)? newHour: this.resolveActualHour(this.props.value);
        let selectedPeriod = (newPeriod!==undefined)? newPeriod : this.resolveActualPeriod(this.props.value);
        let minuto = selectedHour.slice(3, 5);
        let hora = selectedHour.slice(0, 2);
        let value = "0 ".concat(minuto).concat(" ").concat(hora).concat(" ").concat(selectedPeriod).concat(" * *");
        const { onChange } = this.props;
        if (onChange) {
            onChange(value);
        }
    }

    changePeriod = (value) => {
        this.submitChanges(undefined, value);
    }

    changeTime = (value) => {
        this.submitChanges(value.format('HH:mm'), undefined);
    }

    render(){
        const format = 'HH:mm';
        return (<div>
                    <Row gutter={24}>
                        <Col span={18}>
                            <div hidden={this.state.isAdvancedMode || !this.state.showSelectMode}>
                                <Space.Compact block>
                                    <Tooltip title="Selecciona la periodicidad, por defecto es Diariamente">
                                        <Select value={this.resolveActualPeriod(this.props.value)} onChange={this.changePeriod} style={{width:'50%'}}>
                                            {this.getDefaultPeriods()}
                                        </Select>
                                    </Tooltip>
                                    
                                    <Tooltip title="Selecciona la hora en la que deseas que la rutina sea ejecutada, recuerda que esta en formato de 24 Hrs. Por defecto es a las 05:00 AM.">
                                        <div style={{width:'50%'}} >
                                            <TimePicker format={format} value={moment(this.resolveActualHour(this.props.value), format)} onChange={this.changeTime} allowClear={false} style={{width:'100%'}} use12Hours={false} />
                                        </div>
                                    </Tooltip>
                                </Space.Compact>
                            </div>
                            <div hidden={this.state.showSelectMode && !this.state.isAdvancedMode}>
                                <Input
                                    className="common-input"
                                    value={this.props.value}
                                    onChange={(value)=>{
                                        const { onChange } = this.props;
                                        if (onChange) {
                                            onChange(value);
                                        }
                                    }}
                                    />
                            </div>
                        </Col>
                        <Col span={6} hidden={!this.state.showSelectMode}>
                            <Tooltip title={"Cambiar a configuración de rutinas ".concat(this.state.isAdvancedMode? "simple": "avanzado")}>
                                <Switch
                                    checkedChildren="Simple"
                                    unCheckedChildren="Avanzado"
                                    onChange={(value)=>{this.setState({isAdvancedMode: value}); }}
                                    />
                            </Tooltip>
                        </Col>
                    </Row>
        </div>)
    }
};

export default HumanCronExpressionInput;