import { Button, Col, Modal, Row, Form, Input, message, Select } from 'antd';
import React, { useState } from 'react';
import { isValidRfc } from '../../../services/utils/rfcValidator';
import { PlusCircleOutlined } from '@ant-design/icons';

export const RfcModal = ({ visible, onAccept, rfcPrev, rfcUpdated }) => {

    const [rfc, setRfc] = useState('');

    const pushRFC = (rfcList) => {
        let newRfc = rfc.toUpperCase();
        if (!isValidRfc(newRfc)) {
            message.error('El RFC ' + newRfc + ' no es valido');
            return;
        }
        if (rfcList.includes(newRfc)) {
            message.error('El RFC ' + newRfc + ' ya esta registrado');
            return;
        }
        rfcList.push(newRfc);
        rfcUpdated(rfcList);
        setRfc('')
    };

    return (
        <Modal
            open={visible}
            title={"Lista de los RFC de los proveedores"}
            onCancel={onAccept}
            onAccept={onAccept}
            footer={[
                <Button className="back-button" key="back" onClick={onAccept}>
                    Aceptar
                </Button>,
            ]}
        >
            <p>
                {
                    "Expresa el RFC de los proveedores de equipos, sistemas, programas o cualquier otro componente de los controles volumétricos."
                }
            </p>
            <Form layout={'vertical'}>
                <Row gutter={24}>
                    <Col span={24}>
                        <p>Agregar un RFC a la lista de proveedores</p>
                    </Col>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Form.Item
                                placeholder="RFC Proveedor"
                                rules={[{ isValidRfc, message: "RFC no valido" }]}>
                                <Input
                                    placeholder="RFC Proveedor"
                                    onChange={(e) => setRfc(e.target.value)}
                                    value={rfc} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button
                                type="primary"
                                onClick={() => pushRFC(rfcPrev)}
                            >
                                <PlusCircleOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Row>
                <Row gutter={24}>
                    {rfcPrev &&
                        <Select
                            style={{ width: '100%' }}
                            value={rfcPrev}
                            options={rfcPrev.map(e => ({value: e}))}
                            open={false}
                            placeholder="Lista de RFC Proveedores"
                            allowClear
                            mode="multiple"
                            onChange={rfcUpdated}
                        ></Select>
                    }
                </Row>
            </Form>
        </Modal>
    );
};