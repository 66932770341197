// import React from 'react';
import { Provider } from 'react-redux';
import { getIsTemporalPassword, isAuthenticated, getCurrentUser } from './security';
import configureStore from './store/configureStore';
import "antd/dist/reset.css";
import './styles/index.css';
import { ConfigProvider, theme } from 'antd';
import ReactDOM from "react-dom/client";
import React from 'react';
import Login from './containers/login';

// import Login from './containers/login';
import Routes from './routes';
import registerServiceWorker from './registerServiceWorker';
import RedemptionCodeComponent from './routes/subscriptions/redemptionCode/redemptionCodeComponent';
// import { ConfigProvider } from 'antd';
import { getMenu } from './menu';
import {MyDistributionPoint} from './context/MyDistributionPoint';

const Router = (props) => {
    const { menu, userData } = props;
    return (
        <MyDistributionPoint>
            <Provider store={configureStore({ userData })}>
                {<Routes menu={menu} />}
            </Provider>
        </MyDistributionPoint>
    );
};

function App() {
    const isTemporalPassword = getIsTemporalPassword();
    registerServiceWorker();
    if (isAuthenticated()) {
        const userData = getCurrentUser();
        if (isTemporalPassword === 'true') {
            return (<RedemptionCodeComponent><Login isTemporalPassword /></RedemptionCodeComponent>);
        } else {
            return (
              <RedemptionCodeComponent>
                <Router
                  {...{ userData, menu: getMenu(userData.permissions) }}
                />
              </RedemptionCodeComponent>
            );
        }
    } else {
        return (<RedemptionCodeComponent><Login isTemporalPassword={false} /></RedemptionCodeComponent>);
    }
}


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        token: {
          colorPrimary: "#33aadd",
          colorInfo: "#33aadd",
        },
        components: {
          Form: {
            labelColor: "rgb(51, 170, 221)",
            fontSize: 15,
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);